import React from "react"
import SEO from "../components/seo"
import styled from "styled-components"
import { GlobalStyle } from "../components/GlobalStyles"
import gormanetLogo  from '../images/gormanet-logo.png'
import devopsbayLogo  from '../images/devopsbay.png'
import lesnaLogo  from '../images/lesna.png'
import posturelogo from '../images/posturescan-logo.png'
import ueLogo from '../images/ue-logo.png';


const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  @media(max-width: 550px) {
    transform: scale(.8);
    min-height: 90vh;
  }
  @media(max-width: 450px) {
    transform: scale(.7);
  }
`
const StyledWrapUE = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: white;
  margin: 0px auto 50px;
  @media(max-width: 650px) {
    position: absolute;
  }
`
const StyledWrapUEText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  @media(max-width: 550px) {
    transform: scale(.8);
  }
`
const StyledGormanet = styled.div`
  height: 480px;
  width: 480px;
  border-radius: 50%;
  position: relative;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  @media(max-width: 750px) {
    height: 400px;
    width: 400px;
  }
  @media(max-width: 650px) {
    height: 350px;
    width: 350px;
  }
  @media(max-width: 400px) {
    height: 300px;
    width: 300px;
  }
`
const StyledGormanetLogo = styled.div`
  height: 200px;
  width: 200px;
  background-image: url(${gormanetLogo});
  background-size: 200px;
  background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  @media(max-width: 650px) {
    height: 150px;
    width: 150px;
    background-size: 150px;
  }
`
const StyledDevopsbay = styled.a`
  height: 250px;
  width: 250px;
  border-radius: 50%;
  position: absolute;
  bottom: -100px;
  left: -50px;
  background-image: linear-gradient(to top,#3d3384 0%,#a222f7 100%);
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 160px;
    width: 160px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${devopsbayLogo});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    transition: .2s;
  }
  &:hover:after {
    height: 170px;
    width: 170px; 
  }
  @media(max-width: 750px) {
    bottom: -150px;    
  }
  @media(max-width: 650px) {
    height: 180px;
    width: 180px;
    bottom: -100px;
    &:after {
      height: 130px;
      width: 130px;
    }
  }
  
`
const StyledLesna = styled.a`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: -100px;
  background-image: linear-gradient(-225deg,#2e2167 0%,#354fa7 51%,#1c799c 100%);
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 120px;
    width: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${lesnaLogo});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    transition: .2s;
  }
  &:hover:after {
    height: 130px;
    width: 130px; 
  }
  @media(max-width: 650px) {
    height: 160px;
    width: 160px;
    left: -80px;
    &:after {
      height: 100px;
      width: 100px;
    }
  }
`

const StyledPosture = styled.a`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  right: 0px;
  background-image: linear-gradient(#0e67b4 0%, 51%,#0e67b4 0%);
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 140px;
    width: 140px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${posturelogo});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    transition: .2s;
  }
  &:hover:after {
    height: 130px;
    width: 130px; 
  }
  @media(max-width: 650px) {
    height: 160px;
    width: 160px;
    top: -90px;
    right: 0px;
    &:after {
      height: 100px;
      width: 100px;
    }
  }
`

const StyledEdziennik = styled.a`
  height: 160px;
  width: 160px;
  border-radius: 50%;
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
  position: absolute;
  right: -100px;
  bottom: 50px;
  text-align: center;
  line-height: 160px;
  color: #fff;
  font-weight: bold;
  pointer-events: none;
`

const StyledUELogo = styled.img`
  height: 50px;
  width: auto;
  @media(max-width: 650px) {
 height: auto;
 width: 95%;
  }
`

const StyledParagraph = styled.p`
font-size: 18px;
font-family: Verdana;
width: 100%;
max-width: 1024px;
margin: 50px 0 150px;
@media(max-width: 650px) {
  width: 100%;
  margin: 10px;
  font-size: 20px;
   }
`

const StyledUELink = styled.a`
font-weight: bold;
color: black;
`
const StyledIMGLink = styled.a`

`

const IndexPage = () => (
  <>
    <GlobalStyle />
    <SEO
      title="Dedykowane rozwiązania IT"
      description=""
    />
    <StyledWrap>
      <StyledGormanet>
        <StyledGormanetLogo />
        <StyledDevopsbay href="https://devopsbay.io" target="_blank" rel="noopener noreferrer" />
        <StyledLesna href="https://lesnahub.pl" target="_blank" rel="noopener noreferrer"/>
        <StyledEdziennik href="/" >E-DZIENNIK</StyledEdziennik>
        <StyledPosture href="/posturescan" ></StyledPosture>
      </StyledGormanet>
    </StyledWrap>

    <StyledWrapUE>    
      <StyledIMGLink href="/posturescan">
        <StyledUELogo src={ueLogo}></StyledUELogo>
      </StyledIMGLink>
    </StyledWrapUE>
      
    <StyledWrapUEText>
      <StyledParagraph>Gormanet spółka z ograniczoną odpowiedzialnością w konsorcjum z Instytutem Biotechnologii i Medycyny Molekularnej i Lab Control spółka z ograniczona odpowiedzialnością realizuje projekt dofinansowany z Funduszy Europejskich. 
      <StyledUELink href="/posturescan"> Dowiedz się więcej</StyledUELink>
      </StyledParagraph>
    </StyledWrapUEText>
    
  </>
)

export default IndexPage
